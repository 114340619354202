import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        categories:[],
    },
    getters: {},
    mutations: {
        UPDATE_CATEGORIES(state, val) {
            for (let valKey in val) {
                let date = new Date(val[valKey].created_at);
                val[valKey].created_at = date.toLocaleDateString();
            }
            state.categories = val
        },
        ADD_NEW_CATEGORY(state, val) {
            for (let valKey in val) {
                // console.log(val);
                let date = new Date(val.created_at);
                val.created_at = date.toLocaleDateString();
            }
            state.categories.push(val)
        },
        UPDATE_CATEGORY(state, val) {
            debugger;
            for (let key in state.categories) {
                const category = state.categories[key];
                if(category.id===val.id){
                    state.categories[key].name = val.name;
                }
            }

        }
    },
    actions: {
        getCategories({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/admin/comp_category')
                    .then(response => {
                        commit('UPDATE_CATEGORIES', response.data.categories);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addNewCategory({commit},payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/admin/comp_category/create',payload)
                    .then(response => {
                        commit('ADD_NEW_CATEGORY', response.data.category);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateCategory({commit},payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/admin/comp_category/update',payload)
                    .then(response => {
                        commit('UPDATE_CATEGORY', response.data.category);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
