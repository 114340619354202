import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        complaintsTotal: 0,
        activeComplaintsCount: 0,
        activeMunicipalitiesCount: 0,
        userCount: 0,
        fourNumbersData: [
            {
                icon: 'FileTextIcon',
                color: 'light-primary',
                title: '230k',
                subtitle: 'Total Complaints',
                customClass: 'mb-2 mb-xl-0',
            },
            {
                icon: 'CheckCircleIcon',
                color: 'light-success',
                title: '8.549k',
                subtitle: 'Done Complaints',
                customClass: 'mb-2 mb-xl-0',
            },
            {
                icon: 'HomeIcon',
                color: 'light-warning',
                title: '1.423k',
                subtitle: 'Municipality',
                customClass: 'mb-2 mb-sm-0',
            },
            {
                icon: 'UserIcon',
                color: 'light-info',
                title: '$9745',
                subtitle: 'User',
                customClass: '',
            },
        ],
        chartData: {
            xAxisData: [],
            series: [],
        },
        Top10Users: [],
        Top10Municipalities: [],
    },
    getters: {},
    mutations: {
        UPDATE_Statistics_Counts(state, val) {
            state.complaintsTotal = val.complaints_counts;
            state.activeComplaintsCount = val.active_complaints_counts;
            state.activeMunicipalitiesCount = val.active_belediye_count;
            state.userCount = val.users_count;

            // Four Numbers Data
            state.fourNumbersData[0].title = val.complaints_counts;
            state.fourNumbersData[1].title = val.complaints_counts - val.active_complaints_counts;
            state.fourNumbersData[2].title = val.active_belediye_count;
            state.fourNumbersData[3].title = val.users_count;

            // Chart Data
            for (let i = 0; i < val.complaints_counts_by_month.length; i++) {
                const item = val.complaints_counts_by_month[i];
                state.chartData.xAxisData.push(item['month/year']);
                state.chartData.series.push(item.count);
            }

            // Top 10 Users
            state.Top10Users = [];
            for (let i = 0; i < val.most_ten_active_user.length && i < 5; i++) {
                const item = val.most_ten_active_user[i];
                state.Top10Users.push({
                    mode: item.name,
                    payment: item.count,
                    odd: i % 2 == 0,
                    id: item.user_id,
                });
            }

            // Top 10 Municipalities
            state.Top10Municipalities = [];
            for (let i = 0; i < val.most_ten_active_belediye.length && i < 5; i++) {
                const item = val.most_ten_active_belediye[i];
                state.Top10Municipalities.push({
                    mode: item.il_name + " / " + item.ilce_name,
                    payment: item.count,
                    types: item.ilce_name,
                    odd: i % 3 == 0,
                });
            }
        },
    },
    actions: {
        getStatistics({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/statistic/getStatistics')
                    .then(response => {
                        commit('UPDATE_Statistics_Counts', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
