import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import municipalities from "./municipalities"
import complaints from "./complaints"
import user from "./user"
import  chat from "./chat"
import statistics from "@/store/statistics";
import ads from "@/store/ads";
import categories from "@/store/categories";
import satisfaction from "@/store/satisfaction";
import employeeChat from "@/store/employeeChat";
import employees from "@/store/employees";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        municipalities,
        complaints,
        user,
        chat,
        statistics,
        ads,
        categories,
        satisfaction,
        employeeChat,
        employees,
        'app-ecommerce': ecommerceStoreModule,
    },
    strict: process.env.DEV,
})
