import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        iller: [],
        ilceler: [],
        belediyeler: [],
    },
    getters: {},
    mutations: {
        UPDATE_Iller(state, val) {
            state.iller = val
        },
        UPDATE_Ilceler(state, val) {
            state.ilceler = val
        },
        UPDATE_belediyeler(state, val) {
            state.belediyeler = val
        },
    },
    actions: {
        getIller({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/beled')
                    .then(response => {
                        let data = response.data.data
                        let iller = [];
                        for (let item of data) {
                            iller.push({id: item.id, label: item.name})
                        }
                        commit('UPDATE_Iller', iller);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getIlceler({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/beled/ilceler/' + payload)
                    .then(response => {
                        let data = response.data.data
                        let ilceler = [];
                        for (let item of data) {
                            ilceler.push({id: item.id, label: item.name})
                        }
                        commit('UPDATE_Ilceler', ilceler);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addNewMuncipilityUser({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/admin/management/add_belediye_admin', myPayload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getMunicipalities({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/admin/management/get_belediyeler')
                    .then(response => {
                        commit('UPDATE_belediyeler', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateUserStatus({commit, state}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/admin/management/change_status', myPayload)
                    .then(response => {
                        let newUser = response.data.admin;
                        let oldUsers = state.belediyeler.slice();
                        let oldUser = oldUsers.filter(el => el.id === myPayload.admin_id)[0]
                        const index = oldUsers.indexOf(oldUser);
                        if (index > -1) {
                            oldUsers.splice(index, 1);
                            oldUsers.splice(index, 0, newUser);

                        }
                        commit('UPDATE_belediyeler',oldUsers);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
