export default [
    // Mancibilty Management
    {
        path: '/belediyeler/ekle',
        name: 'municipalities-add',
        component: () => import('@/views/pages/administration/Municipalities Management/add'),
        meta: {
            resource: 'admin',
            action: 'read',
        },
    },
    {
        path: '/belediyeler/liste',
        name: 'municipalities-list',
        component: () => import('@/views/pages/administration/Municipalities Management/show'),
        meta: {
            resource: 'admin',
            action: 'read',
        },
    },

    // Ads Management
    {
        path: '/ads/list',
        name: 'ads-list',
        component: () => import('@/views/pages/administration/Ads Managment/liste'),
        meta: {
            resource: 'admin',
            action: 'read',
        },
    },
    {
        path: '/ads/add',
        name: 'ads-add',
        component: () => import('@/views/pages/administration/Ads Managment/add'),
        meta: {
            resource: 'admin',
            action: 'read',
        },
    },
    {
        path: '/ads/edit/:id',
        name: 'ads-edit',
        component: () => import('@/views/pages/administration/Ads Managment/edit'),
        meta: {
            resource: 'admin',
            action: 'read',
        },
    },

    // Complaints Categories Management
    {
        path: '/category/list',
        name: 'category-list',
        component: () => import('@/views/pages/administration/Complaint Categories/list'),
        meta: {
            resource: 'admin',
            action: 'read',
        },
    },
]
