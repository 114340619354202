import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        contactChat: [],
        contacts: [],
        filteredContacts:[],
    },
    getters: {},
    mutations: {
        UPDATE_Contacts(state, val) {
            state.contacts = val
        },
        UPDATE_ContactChat(state, val) {
            state.contactChat = val
        },
        UPDATE_filteredContacts(state, val) {
            state.filteredContacts = val
        },
        ADD_New_Message_To_Chat(state, val) {
            state.contactChat.push(val);
        },
        Add_Contact(state, val) {
            state.contacts.splice(0, 0, val);
            //state.contacts.push(val);
        },
    },
    actions: {
        getContacts({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/adminchat/getChatForLogInAdmin')
                    .then(response => {
                        try {
                            commit('UPDATE_Contacts', response.data.reverse());
                        }catch (e){
                            commit('UPDATE_Contacts', response.data);
                        }


                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getChatsById({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/adminchat/getChatByComplaintId/' + myPayload)
                    .then(response => {
                        commit('UPDATE_ContactChat', response.data.messages);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        sendMessage({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/adminchat/add_message', myPayload)
                    .then(response => {
                        commit('ADD_New_Message_To_Chat', response.data.chat_message);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getSingleContactInfo({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/complaint/' + myPayload)
                    .then(response => {
                        debugger;
                        commit('Add_Contact', response.data.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        filterContacts({commit, state}, keyword) {
            if (keyword !== undefined && keyword!=="") {
                let oldContacts = state.contacts.slice();
                oldContacts = oldContacts.filter(function (el) {
                    if(el.title!==null){
                        return el.title.toLowerCase().search(keyword.toLowerCase()) !== -1;
                    }
                });
                commit("UPDATE_filteredContacts",oldContacts);
            }else{
                commit("UPDATE_filteredContacts",state.contacts);
            }
        },
        uploadImage({commit, state}, myPayload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/media/upload', myPayload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
