import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        userData: null,
        tempUserData: null,
        userComplaintsInfo: {
            complaints:[],
            complaintsPagination: {
                page: 1,
                per_page: 10,
                total: 0,
                total_pages: 0
            },
            fourNumbersData: [
                {
                    icon: 'FileTextIcon',
                    color: 'light-primary',
                    title: '230k',
                    subtitle: 'Submitted',
                    customClass: 'mb-2 mb-xl-0',
                },
                {
                    icon: 'UserIcon',
                    color: 'light-info',
                    title: '$9745',
                    subtitle: 'In-proccess',
                    customClass: '',
                },
                {
                    icon: 'CheckCircleIcon',
                    color: 'light-success',
                    title: '8.549k',
                    subtitle: 'Done Complaintsss',
                    customClass: 'mb-2 mb-xl-0',
                },
                {
                    icon: 'HomeIcon',
                    color: 'light-danger',
                    title: '1.423k',
                    subtitle: 'Canceled',
                    customClass: 'mb-2 mb-sm-0',
                },

            ],
        },
    },
    getters: {},
    mutations: {
        UPDATE_USER(state, val) {
            state.userData = val
        },
        UPDATE_TEMP_USER(state, val) {
            state.tempUserData = val;
        },
        UPDATE_USER_COMPLAINTS_INFO(state, val) {
            // Assign Complaints
            state.userComplaintsInfo.complaints = val.comps;
            state.userComplaintsInfo.complaints.push(...val.comps);

                // Four Numbers Data
            for (const countsKey in val.counts) {
                switch (countsKey) {
                    case 'done':
                        state.userComplaintsInfo.fourNumbersData[2].title = val.counts.done;
                        break;
                    case 'submitted':
                        state.userComplaintsInfo.fourNumbersData[0].title = val.counts.submitted;
                        break;
                    case 'canceled':
                        state.userComplaintsInfo.fourNumbersData[3].title = val.counts.canceled;
                        break;
                    case 'in_process':
                        state.userComplaintsInfo.fourNumbersData[1].title = val.counts.in_process;
                        break;
                }
            }
        }
    },
    actions: {
        getUserData({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/user')
                    .then(response => {
                        commit('UPDATE_USER', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        uploadUserImage({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/media/upload', myPayload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateUserInfo({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/dashboard/user/update', myPayload)
                    .then(response => {
                        commit('UPDATE_USER', response.data.admin)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getUserComplaints({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/complaint/for_user/' + data.userId,{'currentPage': data.page})
                    .then(response => {
                        commit("UPDATE_USER_COMPLAINTS_INFO", response.data.data);
                        commit("UPDATE_TEMP_USER", response.data.user);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
