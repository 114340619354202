import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        employees: [],
    },
    getters: {},
    mutations: {
        UPDATE_EMPLOYEES(state, val) {
            state.employees = val
        },
    },
    actions: {
        addEmployee({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/baldiye/management/add_belediye_clisan',payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getEmployees({commit}){
            return new Promise((resolve, reject) => {
                axios
                    .get('/baldiye/management/get_calisanlar')
                    .then(response => {
                        commit('UPDATE_EMPLOYEES', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateEmployeeStatus({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/baldiye/management/change_status',payload)
                    .then(response => {
                        resolve(response)
                    }).catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
