import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        complaints: [],
        singleComplaint: null,
        complaintsPagination: {
            page: 1,
            per_page: 10,
            total: 0,
            total_pages: 0
        },
        employees: [],
    },
    getters: {},
    mutations: {
        UPDATE_Complaints(state, val) {
            state.complaints = val
        },
        Update_Single_Complaint(state, val) {
            state.singleComplaint = val
        },
        Update_complaintsPagination(state, val) {
            state.complaintsPagination.page = val.current_page;
            state.complaintsPagination.per_page = val.per_page;
            state.complaintsPagination.total = val.total;
            state.complaintsPagination.total_pages = val.last_page;
        },
        UPDATE_EMPLOYEES(state, val) {
            state.employees = val;
        }
    },
    actions: {
        getComplaints({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/complaint', {params: myPayload})
                    .then(response => {
                        commit('UPDATE_Complaints', response.data.data.data);
                        commit('Update_complaintsPagination', response.data.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getSingleComplaint({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/complaint/' + myPayload)
                    .then(response => {
                        commit('Update_Single_Complaint', response.data.data);
                        commit('UPDATE_EMPLOYEES', response.data.calisanlar);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateComplaintStatus({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/dashboard/complaint/update_status', myPayload)
                    .then(response => {
                        commit('Update_Single_Complaint', response.data.complaint);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateAssignedEmployee({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/dashboard/complaint/update_calisan', myPayload)
                    .then(response => {
                        commit('Update_Single_Complaint', response.data.complaint);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
