import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        ads: [],
        singleAd: null,
        timeRanges: [],
    },
    getters: {},
    mutations: {
        UPDATE_Ads(state, val) {
            state.ads = val
        },
        UPDATE_SingleAd(state, val) {
            state.singleAd = val
        },
        UPDATE_TimeRanges(state, val) {
            let timeRanges = [];
            for (let time in val) {
                timeRanges.push({
                    title: val[time],
                    value: time
                })
            }
            state.timeRanges = timeRanges;
        }
    },
    actions: {
        uploadImage({commit},data) {
            return new Promise((resolve, reject) => {
                debugger;
                let formData = new FormData();
                formData.append("file", data);
                axios
                    .post('/media/upload', formData)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            });
        },
        getAds({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/admin/ads')
                    .then(response => {
                        commit('UPDATE_Ads', response.data.ads.reverse());
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getTimeRanges({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/admin/ads/get_times')
                    .then(response => {
                        commit('UPDATE_TimeRanges', response.data.times);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getSingleAd({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/admin/ads/get/' + data)
                    .then(response => {
                        commit('UPDATE_SingleAd', response.data.ads);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addNewAd({commit}, data) {
            return new Promise((resolve, reject) => {


                // Upload first Image
                let formData = new FormData();
                formData.append("file", data.image);
                axios
                    .post('/media/upload', formData)
                    .then(response => {
                        data.image = response.data.filePath;

                        // Upload second Image
                        formData = new FormData();
                        formData.append("file", data.second_image);
                        axios.post('/media/upload', formData)
                            .then((res) => {
                                data.second_image = res.data.filePath;

                                //    Add an ad
                                console.table(data);
                                axios
                                    .post('/admin/ads/create', data)
                                    .then(response => {
                                        resolve(response)
                                    })
                                    .catch(error => {
                                        reject(error)
                                    })
                            })
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateAd({commit}, data) {
            return new Promise((resolve, reject) => {

                axios
                    .post('/admin/ads/update/' + data.id, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })

                // Upload first Image
                // let formData = new FormData();
                // formData.append("file", data.image);
                // axios
                //     .post('/media/upload', formData)
                //     .then(response => {
                //         data.image = response.data.filePath;
                //
                //         // Upload second Image
                //         formData = new FormData();
                //         formData.append("file", data.second_image);
                //         axios.post('/media/upload', formData)
                //             .then((res) => {
                //                 data.second_image = response.data.filePath;
                //                 debugger;
                //                 axios
                //                     .post('/admin/ads/update/' + data.id, data)
                //                     .then(response => {
                //                         resolve(response)
                //                     })
                //                     .catch(error => {
                //                         reject(error)
                //                     })
                //             })
                //
                //     })
                //     .catch(error => {
                //         reject(error)
                //     })
            })
        },
        deleteAd({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/admin/ads/remove/' + data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
