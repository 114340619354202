import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        singleSatisfaction: null,
        satisfaction: [],
        totalSatisfaction: 0,
    },
    getters: {},
    mutations: {
        UPDATE_SATISFACTION(state, val) {
            state.satisfaction = val
        },
        UPDATE_TOTAL_SATISFACTION(state, val) {
            state.totalSatisfaction = val
        },
        UPDATE_SINGLE_SATISFACTION(state, val) {
            state.singleSatisfaction = val
        }
    },
    actions: {
        getSatisfactionList({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/complaint', {params: myPayload})
                    .then(response => {
                        commit('UPDATE_SATISFACTION', response.data.data.data);
                        commit('UPDATE_TOTAL_SATISFACTION', response.data.data.total);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getSingleSatisfaction({commit}, myPayload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/complaint/' + myPayload)
                    .then(response => {
                        commit('UPDATE_SINGLE_SATISFACTION', response.data.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
