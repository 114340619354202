export default [
    {
        path: '/pofile/duzenle',
        name: 'pofile-duzenle',
        component: () => import('@/views/pages/public/profile'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/sikayetler/liste',
        name: 'Complaints-list',
        component: () => import('@/views/pages/public/complaints/list'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/istatistiks',
        name: 'Statistics',
        component: () => import('@/views/pages/public/statistics/Statistics'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/sikayet/detaylari/:id',
        name: 'sikayet-detaylari',
        component: () => import('@/views/pages/public/complaints/show'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/dashboard/ecommerce',
        name: 'dashboard-ecommerce',
        component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/dashboard/analytics',
        name: 'dashboard-analytics',
        component: () => import('@/views/dashboard/analytics/Analytics.vue'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/chat/:id',
        name: 'chat-page',
        component: () => import('@/views/pages/public/chat/chatLayout'),
        meta: {
            resource: 'user',
            action: 'edit',
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
        },
    },
    {
        path: '/chat-employee/:id',
        name: 'chat-employee-page',
        component: () => import('@/views/pages/public/employee Chat/chatLayout'),
        meta: {
            resource: 'user',
            action: 'edit',
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
        },
    },
    {
        path: '/user-complaints/:id',
        name: 'user-complaints-info',
        component: () => import('@/views/pages/public/user/userComplaint'),
        meta: {
            resource: 'user',
            action: 'read',
        },
    },
    {
        path: '/user-complaints/:id',
        name: 'complaint-user-info',
        component: () => import('@/views/pages/public/user/userComplaint'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/satisfaction',
        name: 'satisfaction-list',
        component: () => import('@/views/pages/public/satisfaction/list'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/satisfaction/:id',
        name: 'satisfaction-single',
        component: () => import('@/views/pages/public/satisfaction/show'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/employee/add',
        name: 'employee-add',
        component: () => import('@/views/pages/public/employee/add'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
    {
        path: '/employee/list',
        name: 'employee-list',
        component: () => import('@/views/pages/public/employee/list'),
        meta: {
            resource: 'user',
            action: 'edit',
        },
    },
]
